// the supseven main javascript file

(function ($) {
    $.breakpoints.init();
    $.animation.init();
    $.randomize.init();
    $.invertedColor.init();
    $.navigation.init();
    $.stickyheader.init();
    $.newsletter.init();
    $.lazyLoading.init();

    // form select set different color if placeholder is selected
    $('select.form-control').each(function () {
        if ($(this).val() === '') {
            $(this).addClass('placeholder');
        } else {
            $(this).removeClass('placeholder');
        }

        $(this).on('change', function () {
            if ($(this).val() === '') {
                $(this).addClass('placeholder');
            } else {
                $(this).removeClass('placeholder');
            }
        });
    });

    //only animate anniversary logo on first visit
    if ($.cookie('no-animate') === '1') {
        $('.logo-confetti').addClass('no-animate');
    } else {
        $.cookie('no-animate', 1, { expires: 7, path: '/' });
        $('.logo-confetti').removeClass('no-animate');
    }
})(jQuery);
